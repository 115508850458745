import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Collapse, ContentCollapse } from '@hse-design/react';
import { colorBrandDark, spacingSmall } from '@hse-design/tokens';
import { Playground, Props } from 'docz';
import { useState } from 'react';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "collapse"
    }}>{`Collapse`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Collapse } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=6919%3A134'} storybook={'/?path=/story/collapse-collapse--playground'} vue={'/components/HseCollapse/HseCollapse.html'} vueStorybook={'/?path=/story/collapse-collapse--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Collapse — это компонент, который позволяет сохранять большие объемы контента на небольшом пространстве за счет скрытия текста.
Collapse хорошо работает с мобильными интерфейсами, либо в местах, где вертикальное пространство имеет большое значение.`}</p>
    <p>{`Состояние открытости компонента можно контролировать с помощью пропа `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` и обрабатывая `}<inlineCode parentName="p">{`onToggle`}</inlineCode>{`.`}</p>
    <p>{`У Collapse есть два цвета – `}<inlineCode parentName="p">{`default`}</inlineCode>{` и `}<inlineCode parentName="p">{`invert`}</inlineCode>{`. Цвет задается пропом `}<inlineCode parentName="p">{`color`}</inlineCode></p>
    <Playground __position={1} __code={'<Collapse title={\'Здесь находится текст с функцией заголовка\'}>\n  Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать\n  несколько абзацев более менее осмысленного текста рыбы на русском языке, а\n  начинающему оратору отточить навык публичных выступлений в домашних\n  условиях.\n</Collapse>'} __scope={{
      props,
      DefaultLayout,
      Collapse,
      ContentCollapse,
      colorBrandDark,
      spacingSmall,
      Playground,
      Props,
      useState,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Collapse title={'Здесь находится текст с функцией заголовка'} mdxType="Collapse">
    Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного
    текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях.
  </Collapse>
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node="?node-id=9339:35001" mdxType="FigmaEmbed" />
    <FigmaEmbed node="?node-id=9339:35101" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node="?node-id=9339:34982" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "контентный-коллапс"
    }}>{`Контентный коллапс`}</h3>
    <p>{`Используйте компонент `}<inlineCode parentName="p">{`ContentCollapse`}</inlineCode>{` на контентных страницах.
В таком режиме автоматически добавляются вертикальные и горизонтальные отступы.`}</p>
    <Playground __position={5} __code={'<ContentCollapse title={\'Здесь находится текст с функцией заголовка\'}>\n  Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать\n  несколько абзацев более менее осмысленного текста рыбы на русском языке, а\n  начинающему оратору отточить навык публичных выступлений в домашних\n  условиях.\n</ContentCollapse>'} __scope={{
      props,
      DefaultLayout,
      Collapse,
      ContentCollapse,
      colorBrandDark,
      spacingSmall,
      Playground,
      Props,
      useState,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ContentCollapse title={'Здесь находится текст с функцией заголовка'} mdxType="ContentCollapse">
    Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного
    текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях.
  </ContentCollapse>
    </Playground>
    <h3 {...{
      "id": "управление-открытостью"
    }}>{`Управление открытостью`}</h3>
    <p>{`Состояние открытости компонента можно контролировать с помощью пропа `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` и обрабатывая `}<inlineCode parentName="p">{`onToggle`}</inlineCode>{`.`}</p>
    <Playground __position={6} __code={'() => {\n  const [isOpen, setIsOpen] = useState(true)\n  return (\n    <Collapse\n      isOpen={isOpen}\n      onToggle={setIsOpen}\n      title={\'Здесь находится текст с функцией заголовка\'}\n    >\n      Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать\n      несколько абзацев более менее осмысленного текста рыбы на русском языке,\n      а начинающему оратору отточить навык публичных выступлений в домашних\n      условиях.\n    </Collapse>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Collapse,
      ContentCollapse,
      colorBrandDark,
      spacingSmall,
      Playground,
      Props,
      useState,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [isOpen, setIsOpen] = useState(true);
        return <Collapse isOpen={isOpen} onToggle={setIsOpen} title={'Здесь находится текст с функцией заголовка'} mdxType="Collapse">
      Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее
      осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних
      условиях.
    </Collapse>;
      }}
    </Playground>
    <h3 {...{
      "id": "несколько-подряд"
    }}>{`Несколько подряд`}</h3>
    <p>{`Компоненты с `}<inlineCode parentName="p">{`variant="primary"`}</inlineCode>{` (значение по-умолчанию) встают вплотную при расположении их друг за другом`}</p>
    <Playground __position={7} __code={'<>\n  {[1, 2, 3, 4].map(i => (\n    <Collapse key={i} title={\'Здесь находится текст с функцией заголовка\'}>\n      Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать\n      несколько абзацев более менее осмысленного текста рыбы на русском языке,\n      а начинающему оратору отточить навык публичных выступлений в домашних\n      условиях.\n    </Collapse>\n  ))}\n</>'} __scope={{
      props,
      DefaultLayout,
      Collapse,
      ContentCollapse,
      colorBrandDark,
      spacingSmall,
      Playground,
      Props,
      useState,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <>
    {[1, 2, 3, 4].map(i => <Collapse key={i} title={'Здесь находится текст с функцией заголовка'} mdxType="Collapse">
        Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее
        осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних
        условиях.
      </Collapse>)}
  </>
    </Playground>
    <h3 {...{
      "id": "цвет-компонента"
    }}>{`Цвет компонента`}</h3>
    <p>{`У Collapse есть два цвета – `}<inlineCode parentName="p">{`default`}</inlineCode>{` и `}<inlineCode parentName="p">{`invert`}</inlineCode>{`. Цвет задается пропом `}<inlineCode parentName="p">{`color`}</inlineCode>{`.`}</p>
    <p>{`Значение `}<inlineCode parentName="p">{`default`}</inlineCode>{` рекомендуется использовать на светлых фонах, `}<inlineCode parentName="p">{`invert`}</inlineCode>{` – на темных`}</p>
    <Playground __position={8} __code={'() => {\n  return (\n    <>\n      <div style={{ padding: spacingSmall }}>\n        <Collapse title={\'Default color\'}>\n          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam\n          atque consequatur eaque harum illum iste molestias, possimus quas,\n          quos rem saepe unde vitae voluptatum! Animi commodi expedita illo\n          quidem voluptates.\n        </Collapse>\n      </div>\n      <div style={{ padding: spacingSmall, background: colorBrandDark }}>\n        <Collapse title={\'Invert color\'} color={Collapse.Color.invert}>\n          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam\n          atque consequatur eaque harum illum iste molestias, possimus quas,\n          quos rem saepe unde vitae voluptatum! Animi commodi expedita illo\n          quidem voluptates.\n        </Collapse>\n      </div>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Collapse,
      ContentCollapse,
      colorBrandDark,
      spacingSmall,
      Playground,
      Props,
      useState,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        return <>
      <div style={{
            padding: spacingSmall
          }}>
        <Collapse title={'Default color'} mdxType="Collapse">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam atque consequatur eaque harum illum iste
          molestias, possimus quas, quos rem saepe unde vitae voluptatum! Animi commodi expedita illo quidem voluptates.
        </Collapse>
      </div>
      <div style={{
            padding: spacingSmall,
            background: colorBrandDark
          }}>
        <Collapse title={'Invert color'} color={Collapse.Color.invert} mdxType="Collapse">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam atque consequatur eaque harum illum iste
          molestias, possimus quas, quos rem saepe unde vitae voluptatum! Animi commodi expedita illo quidem voluptates.
        </Collapse>
      </div>
    </>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Collapse} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой элемент `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      